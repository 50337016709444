<template>
  <div class="ap-config-wrap">
    <div class="public-breadcrumb">
      {{$t('apConfiguration.Welcome')}} {{'>'}} {{$t('apConfiguration.SystemConfiguration')}} {{'>'}}
      <!-- <span style="color:#3461FF" v-if="activeName === 'first'">{{$t('Assign AP to Customer')}}</span>
      <span style="color:#3461FF" v-if="activeName === 'second'">{{$t('Assing AP to Store')}}</span> -->
      <span style="color:#3461FF">{{$t('apConfiguration.ApConfiguration')}}</span>
    </div>
    <div class="cont">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- <el-tab-pane v-if="userType === 205" :label="$t('apConfiguration.AssignCustomer')" name="first"> -->
        <el-tab-pane v-if="userType === 205 || userType === 201 || userType === 202" :label="$t('apConfiguration.AssignCustomer')" name="first">
          <customer :activeName="activeName"></customer>
        </el-tab-pane>
        <el-tab-pane v-if="userType === 203" :label="$t('apConfiguration.AssignStore')" name="second">
          <store :activeName="activeName"></store>
        </el-tab-pane>
        <el-tab-pane v-if="userType !== 204" :label="$t('apConfiguration.APUpgrade')" name="third">
          <update :activeName="activeName"></update>
        </el-tab-pane>
      </el-tabs>
    </div>
    
  </div>
</template>
<script>
import customer from './customer.vue'
import store from './store.vue'
import update from './update.vue'
// apUpgrade 除了204 其他都有
export default {
  data() {
    return {
      userType: (JSON.parse(localStorage.getItem("userData") || {})).userType,// 201 经销商用户； 202 运维用户； 203 门店总部； 204 门店用户 205 超级管理员
      activeName: ''
    }
  },
  components: {
    customer,
    store,
    update
  },
  mounted() {
    if (this.userType === 205 || this.userType === 201 || this.userType === 202) {
      this.activeName = 'first'
    }
    if (this.userType === 203) {
      this.activeName = 'second'
    }
  },
  computed: {
    // activeName: {
    //   get() {
    //     if (this.userType === 205) {
    //       return 'first'
    //     }
    //     if (this.userType === 203) {
    //       return 'second'
    //     }
    //   },
    //   set(newVal) {
    //     return newVal
    //   }
    // }
  },
  methods: {
    handleClick() {
      console.log('activeName=====', this.activeName)
    }
  }
}
</script>
<style lang="scss" scoped>
.ap-config-wrap{
  .txtalein{
    // height: 64px;
    // line-height: 64px;
    margin-bottom: 20px;
  }
  .cont{
    height: 800px;
    background: #fff;
    // padding: 0 20px;
    border-radius: 6px;
    ::v-deep .el-tabs__header {
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }
}
</style>