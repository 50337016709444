<template>
  <div class="customer-wrap">
    <div class="top">
      <span v-if="userType === 205" style="line-height:34px;font-size: 12px;">{{$t('apConfiguration.Record')}}: {{ tableInfo.total }}</span>
      <span v-if="userType === 201 || userType === 202" style="display: flex">
        <el-input class="search-input" clearable size="small" v-model="tableInfo.info" style="width: 180px;"></el-input>
        <span class="search" @click="getList"><i class="el-icon-search" /></span>
        <el-button style="width: 80px;" class="left" size="small" @click="multibatchTransmit"><img src="@/assets/img/unlink-icon.png"/><span>{{$t ('apConfiguration.Assign')}}</span></el-button>
      </span>
      <span v-if="userType === 205">
         <el-button class="left" size="small" @click="multibatchTransmit"><img src="@/assets/img/batch-icon.png"/><span>{{$t ('apConfiguration.BatchAssign')}}</span></el-button>
         <el-button class="right" size="small" @click="multiReset"><img src="@/assets/img/unlink-icon.png"/><span>{{$t ('apConfiguration.BatchReset')}}</span></el-button>
      </span>
    </div>
    <el-table
      :class="userType === 205 ? 'admin-table' : 'other-table'"
      v-loading="loading"
      ref="multipleTable"
      style="width:100%, margin-bottom: 20px;"
      :data="tableList"
      row-key="coorId"
      @selection-change="handleSelectionChange"
      @sort-change="handleSort"
      >

      <el-table-column
          type="selection"
          width="100"
        >
        <el-table-column
          width="55"
          type="selection">
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.MacAddress')"
        sortable="custom"
        prop="mac_address"
      >
        <el-table-column prop="macAddress" width="205">
          <template slot="header" slot-scope="scope">
            <el-input style="width: 140px" clearable size="small" v-model="tableInfo.macAddress" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.IP')"
        sortable="custom"
        prop="ip_addr"
      >
        <el-table-column prop="ipAddr" width="205" >
          <template slot="header" slot-scope="scope">
            <el-input style="width: 140px" clearable size="small" v-model="tableInfo.ipAddr" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.CustomerName')"
        prop="customerName"
      >
        <el-table-column prop="customerName" width="205">
          <template slot="header" slot-scope="scope">
            <el-input style="width: 140px" clearable size="small" v-model="tableInfo.customerName" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.CustomerCode')"
        prop="customerCode"
      >
        <el-table-column prop="customerCode" width="205">
          <template slot="header" slot-scope="scope">
            <el-input style="width: 140px" clearable size="small" v-model="tableInfo.customerCode" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.CreateTime')"
        sortable="custom"
        prop="create_time"
      >
        <el-table-column prop="createTime" align="center" min-width="280">
          <template slot="header" slot-scope="scope">
           <!-- <el-input style="width: 80px" v-model="tableInfo.startTime" @input="getList" />-
            <el-input style="width: 80px" v-model="tableInfo.endTime" @input="getList" /> -->
            <!-- <el-date-picker
              v-model="tableInfo.startDate"
              type="date"
              @input="getList"
              placement="bottom-start"
              value-format="yyyy-MM-dd"
              >
            </el-date-picker> <span style="margin: 0 5px; height:40px;line-height:40px;"> - </span>
            <el-date-picker
              v-model="tableInfo.endDate"
              type="date"
              @input="getList"
              placement="bottom-start"
              value-format="yyyy-MM-dd"
              >
            </el-date-picker> -->
            <el-date-picker
              clearable
              size="small"
              v-model="tableInfo.startEndDate"
              type="daterange"
              @input="getList"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              />
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <pagination :tableInfo="tableInfo" @changePage="changePage" />
    <el-dialog
      :title="$t('apConfiguration.AssignCustomer')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose" 
      custom-class="dialog_tab"
      width="900px"
      >
      <el-input icon="el-icon-search" size="small" placeholder="" v-model="apName" @input="multibatchTransmit"></el-input>
      <div class="box">
        <el-scrollbar style="height:100%">
          <el-table
            ref="treeTable"
            :data="treeList"
            style="width: 100%;"
            row-key="customerId"
            :props="defaultProps"
            :tree-props="{children: 'treeList',hasChildren: 'hasChildren'}"
            lazy
            :load="load"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-radio-group  v-if="scope.row.type === 1" v-model="radioId" @change="choseMerchant">
                  <el-radio :label="scope.row.customerId">
                    {{ scope.row.name }}
                  </el-radio>
                </el-radio-group>
                <template v-else>
                  {{ scope.row.name }}
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose('cancel')" style="background: #D4D4D4;">{{$t('button.Cancel')}}</el-button>
        <el-button class="base-color-btn" @click="handleConfirm('confirm')">{{$t('button.Confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import pagination from '@/components/pagination'
import { getDistributeMerchantList, agentMerchantList,updateMerchant } from '@/libs/api/ap-config'
export default {
  data() {
    return {
      userType: (JSON.parse(localStorage.getItem("userData") || {})).userType,// 201 经销商用户； 202 运维用户； 203 门店总部； 204 门店用户 205 超级管理员
      loading: true,
      tableList: [],
      choseIds: '', // table列表选中的id合集
      tableInfo:{
        info:'',
        customerName: '',
        customerCode: '',
        storeName: '',
        storeCode:'',
        ipAddr:'',
        macAddress: '',
        agentId: '',
        startEndDate:[],
        startDate: '',
        endDate: '',
        current: 1,
        size: 10,
        total: 0,
        orders: []
      },
      multipleSelection: [],// 多选选中的对象集合
      dialogVisible: false,
      choseInfo: {},
      currentTreeList: [], // 当前层级的数据
      treeList: [],
      apName: '',
      defaultProps: {
        label:'name',
        children: 'treeList'
      },
      checkId: '', // 获取下一层树机构的数据时，点击的当前节点的id 
      radioId:'', // 保存时选中的树节点中的商户
      maps: new Map(),
      addVisible: false
    }
  },
  components: {pagination},
  props: {
    activeName: {
        type: String,
        required: ''
    },
  },
  watch: {
    'activeName': {
      handler(val) {
        if (val === 'first') {
          this.tableList = []
          this.getList()
        }
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.tableInfo.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList()
    },
    getList: debounce (async function() {
      this.loading = true
      let params
      if (this.userType === 201 || this.userType === 202) {
        params = {
          info: this.tableInfo.info,
          current: this.tableInfo.current,
          size: this.tableInfo.size,
          orders: this.tableInfo.orders
        }
      } else {
        params = {
          macAddress: this.tableInfo.macAddress,
          ipAddr: this.tableInfo.ipAddr,
          customerName: this.tableInfo.customerName,
          customerCode: this.tableInfo.customerCode,
          agentId: this.tableInfo.agentId,
          startDate: (this.tableInfo.startEndDate && this.tableInfo.startEndDate[0]) || '',
          endDate: (this.tableInfo.startEndDate && this.tableInfo.startEndDate[1]) || '',
          current: this.tableInfo.current,
          size: this.tableInfo.size,
          orders: this.tableInfo.orders
        }
      }
      console.log('params======', params)
      const res = await getDistributeMerchantList(params)
      if (res.status === 200 && res?.data?.code === 1) {
        this.tableList = res?.data?.data?.records
        this.tableInfo.total = res?.data?.data.total
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning'
        });
      }
      this.loading = false
    }, 1000),
    // 复选框选择商户
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('复选框选择商户========', this.multipleSelection)
    },
    handleRowClick(row) {
      console.log('radioId',row)
      console.log('2222222222radioId',this.radioId)
    },
    // 选中的数据
    choseMerchant(val) {
      console.log('val===========',val)
      this.radioId = val
    },
    
    async load(tree, treeNode, resolve) {
      console.log('tree, treeNode, resolve', tree, treeNode, resolve)
      this.checkId = tree.customerId
      const params = {
        id: this.checkId,
        name: this.apName
      }
      const res = await agentMerchantList(params)
      if (res.status === 200 && res?.data?.code === 1){
        const data = res.data?.data
        const currentTreeList = data.treeList
        setTimeout(() => {
          resolve(currentTreeList)
        })
      } else {
        this.$message.warning(res.data.message)
      }
    },
    // 批量分配   => 获取树数据
    multibatchTransmit:debounce(async function(){
      this.dialogVisible = false
      let arr = [];
      console.log('this.multipleSelection=======',this.multipleSelection)
      if (!this.dialogVisible){
          if (this.multipleSelection?.length === 0) {
          this.$message.warning(this.$t('apConfiguration.BaseStationRequireTips'))
          return
        }
        // const isMerchantBlank = this.multipleSelection?.find((item) => item.merchantId)
        for (var i = 0; i < this.multipleSelection.length; i++) {
          console.log('000000000000000=======',this.multipleSelection)
          if (this.multipleSelection[i].merchantId) {
            this.$message.warning(this.$t('apConfiguration.BaseStationLimitTips'))
            return
          } else {
            // this.choseIds = this.choseIds.concat(this.multipleSelection[i].coorId)
            // this.choseIds += this.multipleSelection[i].coorId + ","
            arr.push(this.multipleSelection[i].coorId)
          }
        }
      }
      this.choseIds = arr.join(',')
      console.log('123123123this.choseIds--------------', this.choseIds)
      this.dialogVisible = true
      const params = {
        id: this.checkId,
        name: this.apName
      }
      const res = await agentMerchantList(params)
      console.log('res的值+++++++++++++++++',res)
      if (res.status === 200 && res?.data?.code === 1) {
        const data = res.data?.data
        if (!this.checkId || this.apName) {
          this.treeList = data.treeList
        }
        // else {
        //   console.log('data.treeList=====',data.treeList)
        //   this.currentTreeList = data.treeList
        // }
        console.log('333333333333this.treeList=====',this.treeList)
      } else {
        this.$message.warning(res.data.message)
      }
    },1000), 
    // 确认保存分配的商户
    handleConfirm() {
      const params = {
        ids: this.choseIds,
        merchantId: this.radioId,
      }
      updateMerchant(params).then(res => {
        if (res.status === 200 && res?.data?.code === 1) {
          // this.$message({
          //   message: 'Batch Transmit success',
          //   type: 'success'
          // });
          this.$message.success(this.$t('apConfiguration.BatchAssignSuccess'))
          this.getList()
        } else {
          console.log('message', res)
          this.$message({
            message: res.data.message,
            type: 'warning'
          });
        }
        this.dialogVisible = false
        this.multipleSelection = []
        this.choseIds = ''
        this.radioId = ''
        this.checkId = ''
        this.treeList = []
        this.currentTreeList = []
        this.$forceUpdate()
      })
    },
    // 取消选择
    handleClose() {
      this.dialogVisible = false
      this.radioId = ''
      this.checkId = ''
      this.treeList = []
      this.currentTreeList = []
      this.$forceUpdate()
    },
    // 批量重置
    multiReset() {
      if (this.multipleSelection?.length === 0) {
        this.$message.warning(this.$t('apConfiguration.BaseStationRequireTips'))
        return
      }
      for(var i = 0; i < this.multipleSelection.length; i++){
        if (!this.multipleSelection[i].merchantId) {
          // this.$message({
          //   message: 'Base station choosed forid',
          //   type: 'warning'
          // });
          this.$message.warning(this.$t('apConfiguration.BaseStationForbitTips'))
          return
        } else {
          this.choseIds += this.multipleSelection[i].coorId + ","
        }
      }
      console.log('this.choseIds', this.choseIds)
      const params = {
        ids: this.choseIds
      }
      updateMerchant(params).then(res => {
        if (res.status === 200 && res?.data?.code === 1) {
          this.$message.success(this.$t('apConfiguration.BatchResetSuccess'))
          this.getList()
          this.multipleSelection = []
          this.choseIds = ''
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.base-color {
  background: #D3FFE7 !important;
}
.base-color-btn{
  background: #28C76F !important; 
  color: #fff;
}
.customer-wrap{
  .top{
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    padding: 0 15px;
    ::v-deep .el-button {
      width: 130px;
      color: #fff;
      border-radius: 6px;
    }
    .search-input{
      ::v-deep .el-input__inner{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .left{
      // margin-right: 10px;
      background: #28C76F;
      position: relative;
      span{
        margin-left: 15px;
      }
    }
    .right{
      background: #FC184D;
      position: relative;
      span{
        margin-left: 15px;
      }
    }
    img{
      width:16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 10px;
    }
    .search{
      display: inline-block;
      height: 32px;
      width: 40px;
      background: #28C76F;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-right: 30px;
      cursor: pointer;
    }
  }
  ::v-deep .admin-table.el-table{
    .el-table__header{
      width: 100% !important;
    }
    th.el-table__cell>.cell{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    td.el-table__cell>.cell{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .is-group tr:nth-child(2) .el-checkbox {
      display: none;
    }
  }
  ::v-deep .other-table.el-table{
    .el-table__header-wrapper .el-table__header .is-group tr:nth-child(2) {
      display: none;
    }
    th.el-table__cell>.cell{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    td.el-table__cell>.cell{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  ::v-deep .el-dialog{
    height: 600px;
    .el-dialog__header{
      height: 70px;
      line-height: 50px;
      width: 100% !important;
    }
    .el-table thead{
      display: none;
    }
    .el-dialog__body{
      height: 430px;
    }
    .el-input{
      margin-bottom: 20px;
    }
    .el-button{
      width: 152px;
      height: 50px;
    }
    .el-tree-node__content{
      height: 45px;
    }
    .el-tree-node__expand-icon.expanded {
      // visibility: hidden;
    }
    .el-table td.el-table__cell{
      border: none;
      width: 100%;
    }
    .el-table td.el-table__cell > .cell{
      justify-content: flex-start;
    }
    .el-table__row{
      display: flex;
    }
    .el-radio{
      margin-top: 5px;
    }
  }
  .box{
    height: 300px;
  }
}
</style>