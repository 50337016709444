<template>
  <div class="upgrade-history-wrap">
    <el-dialog
      :title="$t('apConfiguration.UpgradeHistory')"
      :visible.sync="showHistoryDialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="1300px"
      >
        <div class="record ">{{$t('apConfiguration.Record')}}: {{ tableInfo.total }}</div>
        <el-table
          class="table-cont"
          ref="multipleTable"
          style="width:100%, margin-bottom: 20px;"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="tableList"
          @sort-change="handleSort"
         >
          <el-table-column
            type="index"
            >
            <el-table-column
              width="55"
              type="index"
              style="display:none">
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('apConfiguration.StoreName')"
            prop="storeName"
          >
            <el-table-column prop="storeName" min-width="160px">
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('apConfiguration.ApName')"
            sortable="custom"
            prop="coor_name"
          >
            <el-table-column prop="coorName" min-width="160px">
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('apConfiguration.IP')"
            sortable="custom"
            prop="ip_addr"
          >
            <el-table-column prop="ipAddr" min-width="150px">
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('apConfiguration.oldVer')"
            sortable="custom"
            prop="old_ver"
          >
            <el-table-column prop="oldVer" min-width="120px">
              <template slot="header" slot-scope="scope">
                <el-input  style="width: 80px" clearable size="small" v-model="tableInfo.oldVer" @input="getList"></el-input>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('apConfiguration.newVer')"
            sortable="custom"
            prop="new_ver"
          >
            <el-table-column prop="newVer" min-width="120px">
              <template slot="header" slot-scope="scope">
                <el-input  style="width: 80px" clearable size="small" v-model="tableInfo.newVer" @input="getList"></el-input>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('apConfiguration.UpgradeTime')"
            sortable="custom"
            prop="upgrade_time"
            >
            <el-table-column prop="upgradeTime" min-width="260px">
              <template slot="header" slot-scope="scope">
                  <el-date-picker
                    clearable
                    size="small"
                    style="width:240px"
                    v-model="tableInfo.startEndDate"
                    type="daterange"
                    @input="getList"
                    placement="bottom-start"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    />
                </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('apConfiguration.UpgradeState')"
            sortable="custom"
            prop="upgrade_state"
            >
            <el-table-column  min-width="200px">
              <template prop="upgradeState" slot="header" slot-scope="scope">
                <el-select v-model="tableInfo.upgradeState" clearable style="width: 150px;margin:0 10px" size="small" @change="getList">
                  <el-option
                    v-for="item in upgradeStateList"
                    :key="item.codeId"
                    :label="item.codeName"
                    :value="item.codeId">
                  </el-option>
                </el-select>
              </template>
              <template prop="upgradeStateDesc" slot-scope="scope">
                <span
                  class="upgrade-state"
                  :class="{
                    'status-init': scope.row.upgradeState === 3001,
                    'status-inprogress': scope.row.upgradeState === 3005,
                    'status-success': scope.row.upgradeState === 3003,
                    'status-failed': scope.row.upgradeState === 3004,
                  }"
                  >
                  {{ scope.row.upgradeStateDesc }}
                </span>
              </template>
            </el-table-column>
          </el-table-column>

        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancel-btn" @click="handleClose">{{$t('button.Cancel')}}</el-button>
          <el-button class="save-btn" type="success" @click="save">{{$t('button.Confirm')}}</el-button>
        </span>
        <pagination :tableInfo="tableInfo" @changePage="changePage" />
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { getUpgradeLogList } from '@/libs/api/ap-config'
import pagination from '@/components/pagination'

export default {
  data() {
    return {
      tableList:[],
      tableInfo: {
        storeName:'',
        coorName:'',
        ver:'',
        ipAddr: '',
        oldVer:'',
        newVer:'',
        startEndDate:[],
        upgradeState:'',
        current: 1,
        size: 10,
        total: 0,
        orders:[]
      },
    }
  },
  components:{
    pagination,
  },
  mounted() {

  },
  watch: {
    'showHistoryDialog': {
      handler(val) {
        if (val) {
          this.tableList = []
          this.tableInfo = {
            storeName:'',
            coorName:'',
            ver:'',
            ipAddr: '',
            oldVer:'',
            newVer:'',
            startEndDate:[],
            upgradeState:'',
            current: 1,
            size: 10,
            total: 0,
            orders:[]
          }
          this.getList()
        }
      },
    },
  },
  computed: {
    upgradeStateList() {
      return this.coorStatusList
    }
  },
  props:{
    showHistoryDialog: {
      default: false,
      type: Boolean,
    },
    coorId: {
      default: '',
      type: String,
    },
    coorStatusList:{
      default: [],
      type: Array
    }
  },
  methods:{
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.tableInfo.orders = arr;
      this.getList()
    },
    getList:debounce(async function(){
      try {
        const params = {
          coorId: this.coorId,
          storeName: this.tableInfo.storerName,
          ipAddr: this.tableInfo.ipAddr,
          oldVer: this.tableInfo.oldVer,
          newVer: this.tableInfo.newVer,
          startDate: this.tableInfo.startEndDate && this.tableInfo.startEndDate[0] || '',
          endDate: this.tableInfo.startEndDate && this.tableInfo.startEndDate[1] || '',
          upgradeState: this.tableInfo.upgradeState,
          current: this.tableInfo.current,
          size: this.tableInfo.size,
        }
        const {data} = await getUpgradeLogList(params)
        if (data.state || data.code === 1) {
          console.log('data====',data)
          const res = data?.data
          this.tableList = res.records || []
          this.tableInfo.total = res.total
        } else {
          this.$message.warning(data.message)
        }
      } catch (error) {
        this.$message.warning(error)
      }
    }, 1000),
    changePage() {
      this.getList()
    },
    handleClose() {
      this.$emit('handleCloseHistoryDialog')
    },
    save() {
      this.$emit('handleCloseHistoryDialog', 'confirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.upgrade-history-wrap ::v-deep{
  .el-dialog__body{
    padding: 20px;
  }
  .record{
    margin-bottom: 20px;
    font-size: 12px;
  }
  .table-cont{
    .is-group tr:nth-child(2) .el-checkbox {
      display: none !important;
    }
    .upgrade-state{
      display: block;
      width: 110px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      margin: 0 auto;
    }
    .status-init {
      background: #C3E5F8;
    }
    .status-inprogress{
      background: #F6D7BE;
    }
    .status-success{
      background: #C5F6CC;
    }
    .status-failed{
      background: #FFCCCC;
    }
    .action-img{
      display: inline-block;
      width: 14px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>