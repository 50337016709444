<template>
  <div class="customer-wrap">
    <div class="top">
      <span style="line-height:34px;font-size: 12px;">{{$t('apConfiguration.Record')}}: {{ tableInfo.total }}</span>
      <span>
         <el-button class="left" size="small" @click="multibatchTransmit"><img src="@/assets/img/batch-icon.png"/><span>{{$t ('apConfiguration.BatchAssign')}}</span></el-button>
          <el-button class="right" size="small" @click="multiReset"><img src="@/assets/img/unlink-icon.png"/><span>{{$t ('apConfiguration.BatchReset')}}</span></el-button>
      </span>
    </div>
    <el-table
      v-loading="loading"
      class="tablist"
      ref="multipleTable"
      style="width:100%, margin-bottom: 20px;"
      :data="tableList"
      row-key="goodsTagMiddleId"
      @selection-change="handleSelectionChange"
      @sort-change="handleSort"
      >

      <el-table-column
            width="100"
            type="selection"
          >
        <el-table-column
          width="55"
          type="selection"
          style="display:none">
        </el-table-column>
      </el-table-column>
      <el-table-column
         :label="$t('apConfiguration.CustomerName')"
        prop="customerName"
      >
        <el-table-column prop="customerName" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input  style="width: 140px" clearable size="small" v-model="tableInfo.customerName" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.CustomerCode')"
        prop="customerCode"
      >
        <el-table-column prop="customerCode" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input  style="width: 140px" clearable size="small" v-model="tableInfo.customerCode" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.StoreName')"
        prop="storeName"
      >
        <el-table-column prop="storeName" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input  style="width: 140px" clearable size="small" v-model="tableInfo.storeName" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.StoreCode')"
        prop="storeCode"
      >
        <el-table-column prop="storeCode" width="200px">
          <template slot="header" slot-scope="scope">
            <el-input  style="width: 140px" clearable size="small" v-model="tableInfo.storeCode" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.IP')"
        sortable="custom"
        prop="ip_addr"
      >
        <el-table-column prop="ipAddr" width="205px" >
          <template slot="header" slot-scope="scope" >
            <el-input style="width: 140px" clearable size="small" v-model="tableInfo.ipAddr" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.MacAddress')"
        sortable="custom"
        prop="mac_address"
      >
        <el-table-column prop="macAddress" width="205px">
          <template slot="header" slot-scope="scope">
            <el-input style="width: 140px" clearable size="small" v-model="tableInfo.macAddress" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.LastCommunication')"
        sortable="custom"
        prop="coor_time"
      >
        <el-table-column prop="coorTime"  min-width="280px" align="center">
          <template slot="header" slot-scope="scope">
            <!-- <el-input  style="width: 80px" v-model="tableInfo.startTime" @input="getList" />-
            <el-input  style="width: 80px" v-model="tableInfo.endTime" @input="getList" /> -->
             <!-- <el-date-picker
              v-model="tableInfo.lastStartDate"
              type="date"
              @input="getList"
              placement="bottom-start"
              value-format="yyyy-MM-dd"
              >
            </el-date-picker> <span style="margin: 0 5px; height:40px;line-height:40px;"> - </span>
            <el-date-picker
              v-model="tableInfo.lastEndDate"
              type="date"
              @input="getList"
              placement="bottom-start"
              value-format="yyyy-MM-dd"
              style="margin-right: 20px"
              >
            </el-date-picker> -->
            <el-date-picker
              clearable
              size="small"
              style="width:240px"
              v-model="tableInfo.startEndDate"
              type="daterange"
              @input="getList"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.CreateTime')"
        sortable="custom"
        prop="create_time"
      >
        <el-table-column prop="createTime" width="285px" align="center">
          <template slot="header" slot-scope="scope">
            <!-- <el-input  style="width: 80px" v-model="tableInfo.startTime" @input="getList" />-
            <el-input  style="width: 80px" v-model="tableInfo.endTime" @input="getList" /> -->
            <!-- <el-date-picker
              v-model="tableInfo.createStartDate"
              type="date"
              @input="getList"
              placement="bottom-start"
              value-format="yyyy-MM-dd"
              >
            </el-date-picker> <span style="margin: 0 5px; height:40px;line-height:40px;"> - </span>
            <el-date-picker
              v-model="tableInfo.createEndDate"
              type="date"
              @input="getList"
              placement="bottom-start"
              value-format="yyyy-MM-dd"
              style="margin-right: 20px"
              >
            </el-date-picker> -->
            <el-date-picker
              clearable
              size="small"
              style="width:240px"
              v-model="tableInfo.createStartEndDate"
              type="daterange"
              @input="getList"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              />
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <pagination :tableInfo="tableInfo" @changePage="changePage" />
    <el-dialog
      :title="$t('apConfiguration.AssignStore')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose" 
      custom-class="dialog_tab"
      width="900px"
      >
      <el-input icon="el-icon-search" clearable size="small" placeholder="" v-model="searchStoreName" />
      <div class="box">
        <el-scrollbar style="height:100%">
          <el-tree
            ref="tree"
            :data="nextStoreList"
            :props="defaultProps"
            node-key="storeId"
            :filter-node-method="filterNode"
          >
            <span slot-scope="{node,data}">
              <el-radio
                v-model="choseStoreId"
                :label="data.storeId"
                @change="change(data)"
              >
                {{ node.label }}
              </el-radio>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose('cancel')" style="background: #D4D4D4;">{{$t('button.Cancel')}}</el-button>
        <el-button size="small" class="base-color-btn" @click="handleConfirm('confirm')">{{$t('button.Confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import pagination from '@/components/pagination'
import { getDistributeStoreList, agentMerchantList, switchPage,switchCoorStore} from '@/libs/api/ap-config'
export default {
  data() {
    return {
      searchStoreName: '',
      tableList: [],
      tableInfo:{
        customerName: '',
        customerCode: '',
        storeName: '',
        storeCode:'',
        ipAddr:'',
        macAddress: '',
        agentId: '',
        startEndDate:[],
        lastStartDate: '',
        lastEndDate: '',
        createStartEndDate:[],
        createStartDate:'',
        createEndDate:'',
        current: 1,
        size: 10,
        total: 0,
        orders: []
      },
      multipleSelection: [],// 多选选中的对象集合
      dialogVisible: false,
      choseStoreId: '', // 最终选中的storeid信息
      nextStoreList: [], // 渲染树组件的data
      defaultProps: {
        label:'storeName',
        children: 'nextStoreList'
      },
      choseIds: '', // table列表选中的复选框的基站
      loading: true
    }
  },
  props: {
    activeName: {
        type: String,
        required: ''
    },
  },
  components: {pagination},
  watch: {
    'activeName': {
      handler(val) {
        if (val === 'second') {
          this.tableList = []
          this.getList()
        }
      },
      immediate: true
    },
    searchStoreName(val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
  },
  methods: {
    // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.tableInfo.orders = arr;
      this.getList()
    },
    // 过滤请求数据
    filterNode(value, data) {
      console.log('storeName', value, data)
      if (!value) return true;
      return data.storeName.indexOf(value) !== -1;
    },
    // 选择树组件的节点
    change(val) {
      console.log('val=======', val)
      this.choseStoreId = val.storeId
    },
    // 翻页
    changePage() {
      this.getList()
    },
    getList:debounce(async function() {
      this.loading = true
      
      const params = {
        customerName: this.tableInfo.customerName,
        customerCode: this.tableInfo.customerCode,
        storeName: this.tableInfo.storeName,
        storeCode: this.tableInfo.storeCode,
        ipAddr: this.tableInfo.ipAddr,
        macAddress: this.tableInfo.macAddress,
        agentId: this.tableInfo.agentId,
        lastStartDate: (this.tableInfo.startEndDate && this.tableInfo.startEndDate[0]) || '',
        lastEndDate: (this.tableInfo.startEndDate && this.tableInfo.startEndDate[1]) || '',
        createStartDate: (this.tableInfo.createStartEndDate && this.tableInfo.createStartEndDate[0]) || '',
        createEndDate: (this.tableInfo.createStartEndDate && this.tableInfo.createStartEndDate[1]) || '',
        current: this.tableInfo.current,
        size: this.tableInfo.size,
        orders: this.tableInfo.orders
      }
      console.log('params======', params)
      const res = await getDistributeStoreList(params)
      if (res.status === 200 && res?.data?.code === 1) {
        this.tableList = res?.data?.data?.records
        this.tableInfo.total = res?.data?.data?.total
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning'
        });
      }
      this.loading = false
    }, 1000),
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量分配 - 获取树数据
    multibatchTransmit:debounce(async function(){
      let arr = [];
      this.nextStoreList = []
      if (!this.dialogVisible) {
        if (this.multipleSelection?.length === 0) {
          this.$message.warning(this.$t('apConfiguration.BaseStationRequireTips'))
          return
        }
        for (var i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].storeId) {
            this.$message.warning(this.$t('apConfiguration.BaseStationStoreLimitTips'))
            return
          } else {
            // this.choseIds += this.multipleSelection[i].coorId + ","
            arr.push(this.multipleSelection[i].coorId)
          }
        }
      }
      this.choseIds = arr.join(',')
      this.dialogVisible = true
      const params = {
        storeId: localStorage.getItem('storeId'),
        name: this.searchStoreName
      }
      const res = await switchPage(params)
      console.log('res的值+++++++++++++++++',res)
      if (res.status === 200 && res?.data?.code === 1) {
        const data = res.data?.data
        // this.nextStoreList = data?.nextStoreList
        this.nextStoreList.push(data)
        console.log('333333333333change事件=====', this.nextStoreList)
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning'
        });
      }
    }, 1000), 
    // 取消选择
    handleClose() {
      this.dialogVisible = false
      this.choseStoreId = ''
    },
    // 批量分配 - 更新保存
    handleConfirm() {
      console.log('this.choseIds',this.choseIds)
      const params = {
        ids: this.choseIds,
        storeId: this.choseStoreId
      }
      switchCoorStore(params).then(res => {
        if (res.status === 200 && res?.data?.code === 1)  {
          this.$message.success(this.$t('apConfiguration.BatchAssignSuccess'))
          this.getList()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }
      })
      this.dialogVisible = false
      this.multipleSelection = []
      this.choseIds = ''
      this.choseStoreId = ''
    },
    // 批量重置
    multiReset() {
      if (this.multipleSelection?.length === 0) {
        this.$message.warning(this.$t('apConfiguration.BaseStationRequireTips'))
        return
      }
      for(var i = 0; i < this.multipleSelection.length; i++){
        if (!this.multipleSelection[i].storeId) {
          this.$message.warning(this.$t('apConfiguration.BaseStationForbitTips'))
          return
        } else {
          this.choseIds += this.multipleSelection[i].coorId + ","
          console.log('this.multipleSelection[i].coorId', this.multipleSelection[i].coorId)
        }
      }
      const params = {
        ids: this.choseIds
      }
      switchCoorStore(params).then(res => {
        if (res.status === 200 && res?.data?.code === 1) {
          this.$message.success(this.$t('apConfiguration.BatchResetSuccess'))
          this.getList()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }
        this.multipleSelection = []
        this.choseIds = ''
        this.choseStoreId = ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.base-color {
  background: #D3FFE7 !important;
}
.base-color-btn{
  background: #28C76F !important; 
  color: #fff;
}
.customer-wrap{
  .top{
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    padding: 0 15px;
    ::v-deep .el-button {
      width: 130px;
      color: #fff;
      border-radius: 6px;
    }
    .left{
      // margin-right: 10px;
      background: #28C76F;
      position: relative;
      span{
        margin-left: 15px;
      }
    }
    .right{
      background: #FC184D;
      position: relative;
      span{
        margin-left: 15px;
      }
    }
    img{
      width:16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 10px;
    }
  }
    ::v-deep .el-table{
      .el-table__header{
        width: 100% !important;
      }
      th.el-table__cell>.cell{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
      td.el-table__cell>.cell{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .is-group tr:nth-child(2) .el-checkbox {
        display: none;
      }
    }
  
  ::v-deep .el-dialog{
    height: 600px;
    .el-dialog__header{
      height: 70px;
      line-height: 50px;
      width: 100% !important;
    }
    .el-table thead{
      display: none;
    }
    .el-dialog__body{
      height: 430px;
    }
    .el-input{
      margin-bottom: 20px;
    }
    .el-button{
      width: 152px;
    }
    .el-tree-node__content{
      height: 45px;
    }
    .el-tree-node__expand-icon.expanded {
      // visibility: hidden;
    }
    .el-table td.el-table__cell{
      border: none;
      width: 100%;
    }
    .el-table td.el-table__cell > .cell{
      justify-content: flex-start;
    }
    .el-table__row{
      display: flex;
    }
    .el-radio{
      margin-top: 5px;
    }
  }
  .box{
    height: 300px;
  }
}
</style>