<template>
  <div class="update-wrap">
    <div class="top">
      <div class="record ">{{$t('apConfiguration.Record')}}: {{ tableInfo.total }}</div>
      <el-select clearable v-if="userType !== 201 && userType !== 202" class="store mr-left" v-model="storeType" style="width: 150px;margin:0 10px" size="small">
        <el-option
          v-for="item in storeOption"
          :key="item.id"
          :label="item.value"
          :value="item.id">
        </el-option>
      </el-select>
      <!-- <el-button size="small" style="margin: 0 10px" @click="importPackage">Import update package<i class="el-icon-plus"></i></el-button> -->
      <el-upload
        class="upload-demo mr-left"
        action="#"
        :before-upload="beforeUpload"
        :http-request="uploadHttpRequest"
        :show-file-list="false"
      >
        <el-button size="small" type="primary" >
          <span class="select-file-text" v-if="!filesObj.fileName">{{$t('apConfiguration.ImportUpdatePackage')}}</span>
          <span class="select-file-text" v-else>
            {{ filesObj.fileName }}
          </span>
          <i class="el-icon-plus el-icon--right"></i>
        </el-button>
      </el-upload>
      <div class="ver mr-left">{{$t('apConfiguration.LatestVersion')}}:  {{ this.filesObj.ver }}</div>
      <el-button :class="filesObj.fileName ? 'bg-color mr-left' : 'mr-left'" size="small" :disabled="!filesObj.fileName" @click="uploadPackage">{{$t('apConfiguration.Upload')}}</el-button>
      <el-button :class="filesObj.ver? 'bg-color2 mr-left' : 'mr-left'" size="small" :disabled="!filesObj.ver" @click="batchUpgrade">{{$t('apConfiguration.BatchUpgrade')}}</el-button>
      <el-button :class="filesObj.ver? 'bg-color2 mr-left' : 'mr-left'"  size="small" :disabled="!filesObj.ver" @click="handleFilter">{{filterText}}</el-button>
    </div>
    <el-table
      class="table-cont"
      v-loading="loading"
      ref="multipleTable"
      style="width:100%;margin-bottom: 20px;"
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      :data="tableList"
      row-key="coorId"
      @selection-change="handleSelectionChange"
      @sort-change="handleSort"
     >
      <el-table-column
            width="100"
            type="selection"
          >
        <el-table-column
          width="55"
          type="selection"
          style="display:none">
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.CustomerName')"
        prop="customerName"
      >
        <el-table-column prop="customerName" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input clearable size="small" v-model="tableInfo.customerName" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.StoreName')"
        prop="storeName"
      >
        <el-table-column prop="storeName" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input clearable size="small" v-model="tableInfo.storeName" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.ApName')"
        sortable="custom"
        prop="coor_name"
      >
        <el-table-column prop="coorName" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input clearable size="small" v-model="tableInfo.coorName" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.ApVersion')"
        sortable="custom"
        prop="ver"
      >
        <el-table-column prop="ver" width="120px">
          <template slot="header" slot-scope="scope">
            <el-input  clearable size="small" v-model="tableInfo.ver" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.IP')"
        sortable="custom"
        prop="ip_addr"
      >
        <el-table-column prop="ipAddr" width="160px">
          <template slot="header" slot-scope="scope">
            <el-input clearable size="small" v-model="tableInfo.ipAddr" @input="getList"></el-input>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.ConnectState')"
        sortable="custom"
        prop="coor_state"
      >
        <el-table-column prop="coorStateDesc" width="160px">
          <template slot="header" slot-scope="scope">
            <!-- <el-input  style="width: 140px" clearable size="small" v-model="tableInfo.connectState" @input="getList"></el-input> -->
            <el-select v-model="tableInfo.connectState" clearable style="margin:0 10px" size="small" @change="getList">
              <el-option
                v-for="item in connectStateList"
                :key="item.codeId"
                :label="item.codeName"
                :value="item.codeId">
              </el-option>
            </el-select>
          </template>
          <template prop="coorStateDesc" slot-scope="scope">
            <span
              class="coor-state"
              :class="{
                'coor-connecting': scope.row.coorState === 901,
                'coor-success': scope.row.coorState === 902,
              }"
              >
              {{ scope.row.coorStateDesc }}
            </span>
            </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.LastCommunication')"
        sortable="custom"
        prop="coor_time"
      >
        <el-table-column prop="coorTime" width="280px">
         <template slot="header" slot-scope="scope">
            <el-date-picker
              clearable
              size="small"
              style="width: 250px"
              v-model="tableInfo.startEndDate"
              type="daterange"
              @input="getList"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$t('apConfiguration.UpgradeState')"
        sortable="custom"
        prop="upgrade_state"
      >
        <el-table-column width="160px">
          <template prop="upgradeState" slot="header" slot-scope="scope">
            <!-- <el-input  style="min-width: 200px" clearable size="small" v-model="tableInfo.upgradeState" @input="getList"></el-input> -->
            <el-select v-model="tableInfo.upgradeState" clearable  style="min-width:220px, padding:0 20px"  size="small" @change="getList">
              <el-option
                v-for="item in coorStatusList"
                :key="item.codeId"
                :label="item.codeName"
                :value="item.codeId">
              </el-option>
            </el-select>
          </template>
          <template prop="upgradeStateDesc" slot-scope="scope" >
           <span
            class="upgrade-state"
            :class="{
              'status-init': scope.row.upgradeState === 3001,
              'status-inprogress': scope.row.upgradeState === 3005,
              'status-success': scope.row.upgradeState === 3003,
              'status-failed': scope.row.upgradeState === 3004,
            }"
            >
             {{ scope.row.upgradeStateDesc }}
           </span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('apConfiguration.UpgradeHistory')"
        width="130"
      >
        <el-table-column prop="action" width="130px">
          <template slot-scope="scope">
            <img class="action-img" @click="checkHistory(scope.row)" src="@/assets/img/upgrade-icon.png" alt="">
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <pagination :tableInfo="tableInfo" @changePage="changePage" />
    <upgrade-history-dialog :showHistoryDialog="showHistoryDialog" @handleCloseHistoryDialog="handleCloseHistoryDialog" :coorId="coorId" :coorStatusList="coorStatusList"/>
    <confirm-dialog :dialogVisible="batchUpgradeVisible" @handleClose="handleCloseBatchUpgrade" :confirmObj="confirmObj"/>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { getUpgradeApList, getCoorStatus,uploadPkg,upgrade } from '@/libs/api/ap-config'
import pagination from '@/components/pagination'
import upgradeHistoryDialog from './upgrade-history-dialog'
import ConfirmDialog from '@/components/confirm-dialog/index.vue'

export default {
  data() {
    return {
      batchUpgradeVisible: false,
      showHistoryDialog: false,
      confirmObj: {
         type: this.$t('ConfirmDialog.Warning'),
         cancelText: this.$t('ConfirmDialog.ThinkAgain'),
         confirmText: this.$t('ConfirmDialog.Yes'),
         content: this.$t('ConfirmDialog.UpgradeTips'),
      },
      userType: (JSON.parse(localStorage.getItem("userData") || {})).userType,// 201 经销商用户； 202 运维用户； 203 门店总部； 204 门店用户 205 超级管理员
      loading: false,
      filterText: 'Filte Lower Version',
      tableInfo: {
        storeName:'',
        customerName:'',
        coorName:'',
        ver:'',
        ipAddr: '',
        startEndDate:[],
        upgradeState: '',
        current: 1,
        size: 10,
        total: 0,
        orders: []
      },
      storeType: '',
      packageOption:[{id: 1, value: '123'},{id: 2, value: '123'},{id: 3, value: '123'}],
      storeOption: Object.freeze([
        {id:'2', value: this.$t('itemsScenarios.ThisStore')},
        {id: "1", value: this.$t('itemsScenarios.ZimenStore')},
        {id:'0', value: this.$t('itemsScenarios.AllStore')}]
      ), // 商店list
      tableList:[],
      multipleSelection: [],
      coorStatusList:[], // 升级状态list
      connectStateList:[], // 连接状态list
      filesObj: {
        formData: {},
        fileName: "",
        downloadUrl:'',
        ver: ''
      },
      coorId: '', // 点击查看记录的coorId
      init: true
    }
  },
  computed: {},
  components:{
    pagination,
    upgradeHistoryDialog,
    ConfirmDialog
  },
  props: {
    activeName: {
        type: String,
        required: ''
    },
  },
  watch: {
    'activeName': {
      handler(val) {
        if (val === 'third') {
          this.init = true
          this.tableList = []
          this.getCodeList()
          this.getConnectList()
          this.getList()
        }
      },
      immediate: true
    },
  },
  methods:{
    // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.tableInfo.orders = arr;
      this.getList()
    },
    async getCodeList() {
      const { data } = await getCoorStatus({ cateId: 30 })
      if (data.code === 1) {
        this.coorStatusList = data.data || [];
      } else {
        this.$message.warning(data.message)
      }
    },
    async getConnectList() {
      const { data } = await getCoorStatus({ cateId: 9 })
      if (data.code === 1) {
        this.connectStateList = data.data || [];
      } else {
        this.$message.warning(data.message)
      }
    },
    getList: debounce(async function(){
      const params = {
        storeId: localStorage.getItem('storeId'),
        storeType: this.storeType,
        customerName: this.tableInfo.customerName,
        storeName: this.tableInfo.storeName,
        coorName: this.tableInfo.coorName,
        ver: this.tableInfo.ver,
        ipAddr: this.tableInfo.ipAddr,
        connectState: this.tableInfo.connectState,
        lastStartDate: this.tableInfo.startEndDate && this.tableInfo.startEndDate[0] || '',
        lastEndDate: this.tableInfo.startEndDate && this.tableInfo.startEndDate[1] || '',
        upgradeState: this.tableInfo.upgradeState,
        // lowerVer: this.filterText === 'Filte Lower Version' ? this.filesObj.ver : '',
        lowerVer: this.init ? '' : this.filesObj.ver,
        current: this.tableInfo.current,
        size: this.tableInfo.size,
        orders: this.tableInfo.orders
      }
      const res = await getUpgradeApList(params)
      if (res.status === 200 && res?.data?.code === 1) {
        if (this.init) {
          this.filterText = this.$t('apConfiguration.FilterLowerVersion')
        } else {
          this.filterText = this.$t('apConfiguration.CancelFilter')
        }
        const { data } = res?.data
        const records = data.records
        this.tableInfo.total = data.total
        console.log('this.coorStatusList', this.coorStatusList)
        // records.forEach(item => {
        //   this.coorStatusList.forEach(t => {
        //     this.$set(item, 'codeId', t.codeId)
        //     item.codeId = t.codeId
        //     if (item.upgradeState === t.codeId) {
        //       item.upgradeState = t.codeName
        //     }
        //   })
        // });
        this.tableList = records
        console.log('this.tableList', this.tableList)
      } else {
        this.$message.warning(res?.data?.message)
      }
    }, 1000),
    // 复选框选择
    handleSelectionChange(val) {
      this.multipleSelection = []
      val.forEach(item => {
        this.multipleSelection.push(item.coorId)
      });
      console.log('复选框选择商户========', this.multipleSelection)
    },
    // 翻页
    changePage() {
      this.getList()
    },
    // 本地上传包
    beforeUpload(file){
      if (file) {
        this.filesObj.fileName = file.name;
        this.filesObj.ver = ''
      }
    },
    uploadHttpRequest(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      this.filesObj.formData = formData;
    },
    // 上传到服务器
    async uploadPackage() {
      try {
        console.log('this.filesObj.formData',this.filesObj.formData)
        const {data} = await uploadPkg(this.filesObj.formData)
        console.log('data=====', data)
        if (data.state || data.code === 1) {
          this.$message.success(this.$t('apConfiguration.UploadSuccess'))
          let res = data.data || {}
          console.log('res=====', res)
          this.filesObj.downloadUrl = res.downloadUrl
          this.filesObj.ver = res.ver
        } else {
          this.$message.warning(data.message)
        }
      } catch(error) {
        this.$message.warning(error)
      }
    },
    // 升级弹框
    batchUpgrade() {
      if (this.multipleSelection.length === 0) {
        // this.$message.warning('请选择')
        this.$message.warning(this.$t('apConfiguration.SelectTips'))
        return
      }
      if (this.filesObj.ver === '' || this.filesObj.downloadUrl === '') {
        this.$message.warning(this.$t('apConfiguration.UploadPackageTips'))
        return
      }
      this.batchUpgradeVisible = true
    },
    // 升级接口
    async handleCloseBatchUpgrade(val) {
      if (val === 'confirm') {
        try {
          const params = {
            ids: this.multipleSelection.join(),
            ver: this.filesObj.ver,
            pkgUrl: this.filesObj.downloadUrl
          }
          console.log('params====', params)
          const {data} = await upgrade(params)
          if (data.state || data.code === 1) {
            this.$message.success(this.$t('apConfiguration.UpgradeSuccess'))
            this.init = true
            this.getList()
          } else {
            this.$message.warning(data.message)
          }
        } catch(error) {
          this.$message.warning(error)
        }
      }
      this.batchUpgradeVisible = false
    },
    // 过滤按钮
    handleFilter() {
      if (this.filterText === this.$t('apConfiguration.FilterLowerVersion')) {
        this.init = false
      } else {
        this.init = true
      }
      this.getList()
    },
    // 查看历史
    checkHistory(val) {
      this.showHistoryDialog = true
      this.coorId = val.coorId
    },
    handleCloseHistoryDialog() {
      this.showHistoryDialog = false
    },
  }
}
</script>
<style lang="scss" scoped>
.bg-color{
  background: #28C76F;
  color: #fff;
}
.bg-color2{
  background: #3461FF;
  color: #fff;
}
.mr-left{
  margin-left: 20px;
}
.update-wrap ::v-deep{
  .top{
    padding: 0 15px 15px;
    display: flex;
    .el-icon-plus{
      margin-left:15px;
    }
    .record, .ver{
      width: 120px;
      max-width: 300px;
      line-height: 32px;
      font-size: 12px;
    }
    .store{
      width: 150px;
    }
    .upload-demo {
      .select-file-text {
        display: inline-block;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .table-cont{
    .is-group tr:nth-child(2) .el-checkbox {
      display: none !important;
    }
    .coor-state, .upgrade-state{
      display: block;
      width: 110px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      margin: 0 auto;
    }
    .coor-connecting{
      background: #C3E5F8;
    }
    .coor-success{
      background: #C5F6CC;
    }
    .status-init {
      background: #C3E5F8;
    }
    .status-inprogress{
      background: #F6D7BE;
    }
    .status-success{
      background: #C5F6CC;
    }
    .status-failed{
      background: #FFCCCC;
    }
    .action-img{
      display: inline-block;
      width: 14px;
      font-size: 18px;
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
  
}
</style>